import React from "react";

export default function Contact() {
  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Contact us</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Get In Touch !</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              At VisuaLogic, communication is key. Whether you have questions, project ideas, or simply want to learn
              more about how we can assist your business, we're here to help. Reach out via phone, email, or our online
              form, and let's discuss how we can turn your vision into reality. Your success is our priority, and we
              can't wait to hear from you.
            </p>
          </div>

          <div className="grid mt-8 items-center gap-6">
            <div className="lg:col-span-8">
              <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                <form>
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="lg:col-span-6 mb-5">
                      <input name="name" id="name" type="text" className="form-input" placeholder="Name :" />
                    </div>

                    <div className="lg:col-span-6 mb-5">
                      <input name="email" id="email" type="email" className="form-input" placeholder="Email :" />
                    </div>
                  </div>

                  <div className="grid grid-cols-1">
                    <div className="mb-5">
                      <input name="subject" id="subject" className="form-input" placeholder="Subject :" />
                    </div>

                    <div className="mb-5">
                      <textarea
                        name="comments"
                        id="comments"
                        className="form-input textarea h-28"
                        placeholder="Message :"
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
