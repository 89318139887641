import React from "react";
import { Link } from "react-router-dom";

import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
import launchImage from "../assets/images/svg/office-desk.svg";

export default function Services() {
  const team = [
    {
      title: "Creative Fusion",
      icon: "adjust-circle",
      subtext:
        "We revolutionize digital marketing by seamlessly blending artistic creativity with data-driven insights, ensuring impactful brand communication.",
    },
    {
      title: "Tailored Content",
      icon: "circuit",
      subtext:
        "Our expertise lies in crafting visually stunning marketing content tailored to resonate with target audiences across diverse platforms.",
    },
    {
      title: "AI Optimization",
      icon: "fire",
      subtext:
        "We leverage cutting-edge AI technology to analyze market trends and audience behavior, optimizing campaign performance in real-time.",
    },
    {
      title: "Full Solutions",
      icon: "shopping-basket",
      subtext:
        "From graphic design to strategic campaign planning, we offer end-to-end solutions to enhance brand visibility and engagement.",
    },
    {
      title: "Innovative Growth",
      icon: "flower",
      subtext:
        "Through innovative digital marketing strategies, we help businesses unlock their full potential and achieve sustainable growth.",
    },
    {
      title: "Experiential Craft",
      icon: "flip-h",
      subtext:
        "At VisuaLogic, we don't just create content; we craft experiences that captivate, convert, and inspire action.",
    },
  ];

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-orange-600 text-base font-medium uppercase mb-2">What We Do ?</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Creative Solutions</h3>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            {team.map((item, key) => (
              <div
                key={key}
                className={`features p-6 ${
                  key % 2 === 0
                    ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800"
                    : "shadow-xl shadow-slate-100 dark:shadow-slate-800"
                } transition duration-500 rounded-3xl mt-8`}
              >
                <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link
                    to="#"
                    className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium"
                  >
                    {item.title}
                  </Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Work Process</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Efficient Workflow</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              With meticulous planning and seamless collaboration, we deliver exceptional results on time, every time.
            </p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={StrategyImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Strategy</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        In the digital marketing realm, strategy is pivotal. At VisuaLogic, we blend creativity with
                        data-driven insights. Our approach includes market analysis, audience segmentation, and goal
                        alignment, forming a strong basis for campaigns. We stay agile, refining strategies based on
                        real-time feedback. With innovation, we empower clients to navigate the digital landscape,
                        driving meaningful connections.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Development</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        In the realm of development, VisuaLogic takes a meticulous approach. We prioritize innovation
                        and efficiency in every stage of the process. From conceptualization to execution, our team
                        harnesses cutting-edge technology and best practices to bring ideas to life. We emphasize
                        collaboration and communication, ensuring that every project meets the highest standards of
                        quality and functionality. With a focus on continuous improvement, we strive to exceed
                        expectations and deliver solutions that drive long-term success for our clients.
                      </p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={departmentImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
